import { cn } from 'lib/utils'
import MarkdownLib, { type MarkdownToJSX } from 'markdown-to-jsx'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { codeStyle } from './codeStyle'
import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'lib/constants'

import { getAccount } from 'lib/stores/user'
import { getAuthHeaders } from 'lib/utils/token'
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip'
import Spinner from './Spinner'
import Tableau from './chat/messages/Tableau'

interface HighlightedCodeProps {
  children: string
  inline: boolean
  className?: string
}

interface PreProps {
  children: string
}

const Pre: React.FC<PreProps> = ({ children }) => {
  return <>{children}</>
}
interface CodeProps {
  children: string
}

const Code: React.FC<CodeProps> = ({ children }) => {
  return <code className='not-prose text-sm'>{children}</code>
}

const HighlightedCode: React.FC<HighlightedCodeProps> = ({
  className,
  inline,
  children,
}) => {
  const language = className ? className.replace('lang-', '') : 'text'
  return (
    <SyntaxHighlighter
      language={language.toLowerCase()}
      style={codeStyle}
      PreTag={Pre}
      CodeTag={Code}
    >
      {children}
    </SyntaxHighlighter>
  )
}

const WrapperPre: React.FC<{ children: string }> = ({ children }) => {
  return (
    <pre className='not-prose w-min-fit text-wrap px-6 py-2'>{children}</pre>
  )
}

const Link: React.FC<{ href: string; children: string }> = ({
  href,
  children,
}) => {
  try {
    const url = new URL(href)
    return (
      <a
        href={url.href}
        target='_blank'
        rel='noopener noreferrer'
        className='text-primary underline'
      >
        {children}
      </a>
    )
  } catch (error) {
    console.warn('An invalid link was found:', href)
    return <span>{children}</span>
  }
}

// TODO Use the autogenerated Kubb types for this. We should setup the monorepo
// so that we can share code between the frontend and the widget.
type KnowledgeLayerContextAPI = {
  markdown: string
  embedded_viz_url?: string | null
}

enum EntityType {
  dimension = 'dimension',
  metric = 'metric',
  dataset = 'dataset',
  tableau = 'tableau',
  mode = 'mode',
  unknown = 'unknown',
}

function Span({
  children,
  className,
  ...props
}: React.PropsWithChildren<{
  'className'?: string
  'data-type'?: EntityType
  'data-id'?: string
  'data-is-tooltip'?: boolean
}>) {
  const type = props['data-type']
  const id = props['data-id']
  const isTooltip = props['data-is-tooltip']

  if (type === 'unknown') {
    return (
      <span className='rounded-md bg-yellow-50 px-2 font-mono text-sm text-yellow-600'>
        {children}
      </span>
    )
  }

  if (type != null && id != null) {
    if (isTooltip) {
      return (
        <Tooltip delayDuration={0}>
          <TooltipTrigger
            className={cn(
              'rounded-md bg-yellow-50 px-2 font-mono text-sm text-yellow-600',
              type === EntityType.dimension && 'bg-green-50 text-green-600',
              type === EntityType.metric && 'bg-blue-50 text-blue-600',
              type === EntityType.mode && 'bg-teal-50 text-teal-600',
              type === EntityType.tableau && 'bg-purple-50 text-purple-600',
              className,
            )}
            {...props}
          >
            {children}
          </TooltipTrigger>
          <TooltipContent
            className='max-w-lg bg-white p-4 text-primary'
            align='start'
            side='bottom'
          >
            <SpanContent id={id} type={type} />
          </TooltipContent>
        </Tooltip>
      )
    }
    return (
      <div className={className} {...props}>
        <SpanContent id={id} type={type} />
      </div>
    )
  }

  return (
    <span className={className} {...props}>
      {children}
    </span>
  )
}

function SpanContent({ id, type }: { id: string; type: string }) {
  const { data, isPending, error } = useQuery({
    queryKey: ['knowledge', { type, id }],
    queryFn: async () => {
      const params = new URLSearchParams({ entity_id: id, entity_type: type })
      const url = `${API_URL}/v3/orgs/${getAccount()}/knowledge_layer/context`
      const response = await fetch(`${url}?${params.toString()}`, {
        credentials: 'include',
        headers: getAuthHeaders(),
      })
      if (!response.ok) throw new Error('Something went wrong!')
      return (await response.json()) as KnowledgeLayerContextAPI
    },
  })
  return isPending ? (
    <div className='flex h-full w-full items-center justify-center'>
      <Spinner size={0.5} />
    </div>
  ) : error ? (
    <div className='flex h-full w-full items-center justify-center'>
      <span className='text-red-500'>Something went wrong.</span>
    </div>
  ) : data.embedded_viz_url ? (
    <Tableau url={data.embedded_viz_url} />
  ) : (
    <Markdown className='text-sm'>{data.markdown}</Markdown>
  )
}

const options: MarkdownToJSX.Options = {
  overrides: {
    code: {
      component: HighlightedCode,
    },
    pre: {
      component: WrapperPre,
    },
    a: {
      component: Link,
    },
    span: {
      component: Span,
    },
  },
}

export function Markdown({
  className,
  children,
}: React.PropsWithChildren<{ className?: string }>) {
  if (typeof children !== 'string') {
    /* eslint-disable-next-line no-console */
    console.warn(
      `<Markdown> component was passed a non-string child!. Child was of type: ${typeof children}`,
    )
    return <>{children}</>
  }
  return (
    <MarkdownLib
      className={cn(
        'break-words [&>:first-child]:mt-0 [&>:last-child]:mb-0',
        className,
      )}
      options={options}
    >
      {children}
    </MarkdownLib>
  )
}
