import { ReactComponent as AiChatIcon } from 'lib/icons/ai-chat.svg'
import { ChatMessage } from 'lib/models/message'
import { cn } from 'lib/utils'
import { getMessageKey } from 'lib/utils/message'
import { Fragment, useEffect, useMemo, useState } from 'react'
import ChatMessageWidget from './ChatMessage'
import Spinner from 'components/Spinner'
import { MessageTooltips } from './MessageTooltips'
import { Check, ChevronDown } from 'lucide-react'
import MessageMarkdown from './MessageMarkdown'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/Collapsible'

interface MessageGroupProps {
  messages: ChatMessage[]
  sender: 'user' | 'system'
  isStreaming?: boolean
  showCopyActions?: boolean
  userMessage: ChatMessage
  isPopoverFeedbackChat?: boolean
}

export default function MessageGroup({
  messages,
  sender,
  isStreaming,
  userMessage,
  showCopyActions = true,
  isPopoverFeedbackChat = false,
}: MessageGroupProps) {
  const progressMessages = useMemo(
    () =>
      messages.filter(
        (message) =>
          message.render_type === 'TEMPORARY' ||
          message.render_type === 'LOADING',
      ),
    [messages],
  )
  const thinkingMessages = useMemo(
    () => messages.filter((message) => message.render_type === 'SOFT'),
    [messages],
  )
  const chatMessages = useMemo(
    () => messages.filter((message) => message.render_type === 'STANDARD'),
    [messages],
  )

  const [thinkingOpen, setThinkingOpen] = useState(isStreaming)
  useEffect(() => setThinkingOpen(isStreaming), [isStreaming])

  return (
    <div
      className={cn(
        'flex flex-col font-normal',
        !isPopoverFeedbackChat && 'md:flex-row',
        sender === 'user'
          ? 'max-w-[75%] self-end'
          : 'w-full max-w-full self-start',
        sender === 'user' ? (isPopoverFeedbackChat ? 'my-2' : 'my-8') : 'my-2',
      )}
    >
      {sender === 'system' && (
        <AiChatIcon className='mb-3 mr-6 h-[2rem] w-[2rem] flex-shrink-0' />
      )}
      <div className='flex min-w-0 flex-1 flex-col gap-4'>
        {progressMessages.length + thinkingMessages.length > 0 && (
          <Collapsible open={thinkingOpen} onOpenChange={setThinkingOpen}>
            <CollapsibleTrigger asChild>
              <button className='group/progress-messages flex h-8 items-center gap-3 rounded-xl bg-neutral-50 px-3 text-sm font-medium'>
                {progressMessages.length === 0 ? (
                  <Check className='h-4 w-4' />
                ) : (
                  <Spinner size={0.5} />
                )}
                {progressMessages.length === 0 ? (
                  <span>Completed</span>
                ) : progressMessages[progressMessages.length - 1]?.markdown !=
                  null ? (
                  <MessageMarkdown>
                    {progressMessages[progressMessages.length - 1].markdown}
                  </MessageMarkdown>
                ) : (
                  <span>Running</span>
                )}
                {thinkingMessages.length > 0 && (
                  <ChevronDown className='h-4 w-4 transition group-aria-expanded/progress-messages:rotate-180' />
                )}
              </button>
            </CollapsibleTrigger>
            <CollapsibleContent className='mt-2.5 flex flex-col gap-3 border-l-2 border-neutral-200 px-3'>
              {thinkingMessages.map((thinkingMessage, index, all) => (
                <Fragment key={thinkingMessage.id}>
                  <MessageMarkdown className='text-neutral-400'>
                    {thinkingMessage.markdown}
                  </MessageMarkdown>
                  {index === all.length - 1 && isStreaming && (
                    <p className='animate-pulse text-sm'>Thinking...</p>
                  )}
                  <div className='my-3 h-px bg-neutral-200 last:hidden' />
                </Fragment>
              ))}
            </CollapsibleContent>
          </Collapsible>
        )}
        {chatMessages.map((message, index) => (
          <ChatMessageWidget
            message={message}
            key={getMessageKey(message.id, message.response_index)}
            groupIndex={index}
            isPopoverFeedbackChat={isPopoverFeedbackChat}
          />
        ))}
        {sender === 'system' && !isStreaming && showCopyActions && (
          <MessageTooltips
            chatMessages={chatMessages}
            userMessage={userMessage}
            isPopoverFeedbackChat={isPopoverFeedbackChat}
            className='flex'
          />
        )}
      </div>
    </div>
  )
}
